import React from "react";
import { Link, navigate } from "gatsby";

import "../styles/styles.scss";

import Layout from "../components/layout";
import { AuthProvider, useAuth } from "../context/auth";
import SubmissionTable from "../components/SubmissionTable";
import HomeFeatureBox from "../components/homeFeatureBox";
import { logDev } from "../helpers/globalHelpers";
import { parseHash } from "../utilities/utilities";

const Index = () => {
  const { user, dba } = useAuth();

  const hash = window.location.hash;
  const hashValues = parseHash(hash);

  if (hashValues.type === "recovery") {
    navigate("/passwordReset");
    return <div />;
  } else if (user && dba) {
    return (
      <Layout pageName="Home">
        <p class="title">Frontier Compliance Suite</p>
        <div class="columns">
          <HomeFeatureBox
            title="Compliance Forms"
            description="Access and submit all your personal Compliance Forms"
            linkText="Access and submit forms"
            identifier="complianceFeatureBox"
            linkTo="/selectForm?formType=complianceForms"
          />
          <HomeFeatureBox
            title="Customer Information Forms"
            description="Access and submit all Customer Information Forms for your DBA"
            linkText="Access and submit forms"
            identifier="cifFeatureBox"
            linkTo="/selectForm?formType=customerInformationForms"
          />
          <HomeFeatureBox
            title="Resources"
            description="Download agreement templates and other useful compliance resources"
            linkText="View resources"
            identifier="resourcesFeatureBox"
            linkTo="/resources"
          />
        </div>
        <SubmissionTable formType="status:openSubmissions" />
      </Layout>
    );
  } else if (user) {
    return (
      <Layout pageName="Home">
        <p class="title">Frontier Compliance Portal</p>
        <p>
          Please contact your administrator. You are not yet associated with a
          DBA.
        </p>
      </Layout>
    );
  } else {
    navigate("/login");
    return <div />;
  }
};

const IndexPage = () => (
  <AuthProvider>
    <Index />
  </AuthProvider>
);

export default IndexPage;
