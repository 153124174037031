import React from "react";
import { navigate } from "gatsby";

const HomeFeatureBox = ({
  title,
  description,
  linkText,
  identifier,
  linkTo,
}) => (
  <div
    class="column box fpHomeFeaturesItem"
    style={{ cursor: "pointer" }}
    onClick={() => {
      navigate(linkTo);
    }}
    onMouseEnter={() =>
      document.getElementById(identifier).classList.add("active")
    }
    onMouseLeave={() =>
      document.getElementById(identifier).classList.remove("active")
    }
  >
    <p class="subtitle">
      <strong>{title}</strong>
    </p>
    <p>{description}</p>
    <div class="fpHomeFeatureLinkPadding">
      <a id={identifier}>{linkText}</a>
    </div>
  </div>
);

export default HomeFeatureBox;
